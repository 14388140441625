import React from 'react'
import "./login.css"
import Metamask from "../../assets/img/MetaMask_Fox 3.svg"
import Truewallet from "../../assets/img/true_wallet.svg"
import Outlined from "../../assets/img/Outlined.svg"
import Safepal from "../../assets/img/safewallet.png"
import Tokenpocket from "../../assets/img/TokenPocket.svg"
import { UncontrolledTooltip } from 'reactstrap';
export default function LoginButtons() {
    return (
        <div class="home-btn">
            <div class="selectcurbtnsec">
                <a class="btn-outline-color-secondary " id="UncontrolledTooltipsignup" href="https://web2.cn.egoldminer.shop/register">注册</a>

                <a class="btn-color-primary " id="UncontrolledTooltiplogin" href="https://web2.cn.egoldminer.shop/">登录</a>
            </div>
            <div >
                <a class="btn-get-secondry web3-btn  callogbtn" style={{ textDecoration: "none", cursor: "pointer" }} href="https://web3.cn.egoldminer.shop/" >
                    <div class="slidericon">
                        <span><img src={Metamask} alt="" /></span>
                        <span><img src={Truewallet} alt="" /></span>
                        {/* <span><img src={Safepal} alt="" style={{ height: 22 }} /></span> */}
                        <span><img src={Tokenpocket} alt="" /></span>
                    </div>
                    <a style={{ color: "unset" }}>web3登录 {" "}
                        <span class="web3-arrow"><img src={Outlined} alt="" /></span></a>
                </a>

            </div>
        </div>
    )
}
