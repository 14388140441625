import React from "react";
import { useNavigate } from "react-router-dom";
export default function Miners(props) {
  const history = useNavigate();
  return (
    <div
      class={
        props.data.bg == "grey"
          ? "minerAvailableCard"
          : "minerAvailableCardWhite"
      }
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        history("/minerdetails", { state: { props } });
      }}
    >
      <div class={"minerAvailableTitle"}>
        Egold矿工<span class="">{props.data.type}</span>
      </div>
      {props.data.new == true ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="newedition">新版本</p>
        </div>
      ) : null}

      <div
        class="minerAvailableImage"
        style={props.data.new == true ? { marginTop: 5 } : {}}
      >
        <img src={props.data.img} class="img-fluid" />
      </div>

      <a class="minerAvailablebtn">功率 : {props.data.power} mhs</a>
      <a class="minerAvailablebtn">
        价格 :{" "}
        <p
          style={{
            margin: 0,
            paddingLeft: 5,
            fontSize: 18,
            fontWeight: "500",
            color: "#253237",
          }}
        >
          {props.data.price}美元
        </p>
      </a>
    </div>
  );
}
