import React from "react";
import "./index.css";
import NFT from "../../assets/img/nft.svg";
export default function index() {
  return (
    <div>
      <section id="anft" class="anft section-bg">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-12">
              <h3 data-aos="fade-up" class="text-center anfth3">
                ANFT 技术
              </h3>
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                class=" text-justify anftp"
              >
                ANFT是简版的NFT，它默认将编程或业务逻辑编码到其中。
                常规NFT只有图像和附件，然而ANFT拥有附加业务逻辑的智能合约。
              </p>
              <span
                class="text-center d-block"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <img src={NFT} height={105} alt="" />
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======= how-it-work ======= --> */}
      <section id="how-it-work" class="clients section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h3 style={{ fontSize: 36, color: "#253237", fontWeight: "600" }}>
              它如何工作
            </h3>
          </div>

          <div class="how-works d-flex flex-row d-flex align-items-center">
            <p class="">购买Egold矿工</p>
            <span class="">1</span>
          </div>
          <div class="how-works d-flex flex-row d-flex align-items-center">
            <p class="">
              转到
              <a
                href="https://www.egoldproject.com/"
                style={{ color: "#FFBD02" }}
                target="_blank"
              >
                egoldproject.com
              </a>{" "}
              网站
            </p>
            <span class="">2</span>
          </div>
          <div class="how-works d-flex flex-row d-flex align-items-center">
            <p class="">使用你购买时的同一个钱包连接app</p>
            <span class="">3</span>
          </div>
          <div class="how-works d-flex flex-row d-flex align-items-center">
            <p class="">权益质押Egold矿工</p>
            <span class="">4</span>
          </div>

          <div class="how-works d-flex flex-row d-flex align-items-center">
            <p class="">无权益质押Egold矿工</p>
            <span class="">5</span>
          </div>

          <div class="how-works d-flex flex-row d-flex align-items-center">
            <p class="">支付挖矿燃料&折旧费用</p>
            <span class="">6</span>
          </div>

          <div class="how-works d-flex flex-row d-flex align-items-center">
            <p class="">收集Egold</p>
            <span class="">7</span>
          </div>
        </div>
      </section>

      {/* //ABOUT SECTION */}
      <section id="about" class="statistics section-bg">
        <div class="container" data-aos="fade-up">
          <div class="row justify-content-between">
            <div class="col-lg-12">
              <h3 data-aos="fade-up" class="text-center abouth3">
              关于
              </h3>
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                class="text-justify aboutpdesc"
              >
                Egold项目是一项倡议，它旨在开展一场与区块链技术并肩齐驱的crypto代币经济学辩论。通常，我们会为矿工、交易者和crypto打造egold新工具。我们还利用区块链的燃烧特性来构建最佳的通货紧缩代币经济学。
              </p>
              <br />
              <span
                class="text-center d-block aboutp"
                data-aos="fade-up"
                data-aos-delay="300"
                style={{ fontSize: 26, fontWeight: "400" }}
              >
                更多信息，请访问
                <a
                  href="https://www.egoldproject.com/"
                  style={{ color: "#FFBD02", fontWeight: "600" }}
                  target="_blank"
                >
                  www.egoldproject.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
