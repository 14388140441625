import React from 'react'
import "./index.css"

import IndiMiners from "./IndiMiners"
export default function index() {
    const miners = [21, 22, 23, 24, 25, 26, 27, 28]
    return (
        <section id="miners" class="miners">
            <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h3 style={{ fontSize: 36, color: "#253237", fontWeight: '600' }}>可用矿工</h3>
                    <h6 style={{ fontSize: 24, color: "#253237", fontWeight: '400' }}>可出售矿工名单</h6>
                </div>
                <div class="row">
                    {

                        miners.map((items) => {
                            return (

                                <div class="col-md-4" >
                                    <IndiMiners type={items} />


                                </div>
                            )
                        })
                    }

                </div>
            </div>

        </section>
    )
}
